var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"sf-discover-card",class:{
        'sf-discover-card--has-meta': _vm.label || _vm.year,
        'sf-dicover-card--video': _vm.vimeoUrl
    },attrs:{"data-card-type":_vm.cardType,"data-card-orientation":_vm.orientation,"data-video-state":_vm.videoState}},[_c(_vm.cardElement,_vm._b({tag:"component",staticClass:"sf-discover-card__clickable"},'component',_vm.cardAttrs,false),[(!_vm.vimeoUrl && !_vm.tips.length)?_c('div',{staticClass:"sf-discover-card__image"},[(_vm.image && _vm.image.url_sm)?_c('lazy-img',{staticClass:"lazy-img--cover",attrs:{"src":_vm.image.url_sm,"src-tiny":_vm.image.url_tiny,"srcset":`
                    ${_vm.image.url_xs} 320w,
                    ${_vm.image.url_sm} 600w,
                    ${_vm.image.url_md} 800w
                `,"sizes":"\n                    (max-width: 380px) 320px,\n                    600px\n                ","width":_vm.image.width,"height":_vm.image.height,"alt":_vm.image.alt || ''}}):_vm._e(),_vm._v(" "),(_vm.date)?_c('div',{staticClass:"sf-discover-card__date"},[_c('sf-date-badge',{attrs:{"date":_vm.date}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sf-discover-card__body"},[(_vm.cardType === 'quote')?_c('sf-icon',{attrs:{"icon":"blockquote"}}):_vm._e(),_vm._v(" "),_c(_vm.titleTag,{tag:"component",staticClass:"sf-discover-card__title"},[_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")])],1),_vm._v(" "),(_vm.tips.length > 0)?_c('div',{staticClass:"sf-discover-card__tips"},_vm._l((_vm.tips),function(tip,index){return _c('div',{key:index,staticClass:"sf-discover-card__tips__tip"},[_c('h3',[_vm._v("tip "+_vm._s(index + 1))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(tip))])])}),0):_vm._e(),_vm._v(" "),(_vm.cardType === 'video' && _vm.vimeoUrl)?_c('div',{staticClass:"sf-discover-card__video"},[(_vm.cardType === 'video' && _vm.vimeoUrl)?_c('div',{staticClass:"sf-discover-card__play"}):_vm._e(),_vm._v(" "),_c('sf-vimeo-embed',{staticClass:"vimeo-embed--cover",attrs:{"title":_vm.title,"url":_vm.vimeoUrl,"accessible-url":_vm.accessibleVimeoUrl},on:{"play":function($event){_vm.videoState = 'playing'},"pause":function($event){_vm.videoState = 'paused'},"ended":function($event){_vm.videoState = 'ended'}}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"sf-discover-card__meta"},[(_vm.label && _vm.labelUrl)?[(_vm.isExternalLabelUrl)?_c('a',{attrs:{"href":_vm.labelUrl,"tabindex":"-1"}},[_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")]):_c('nuxt-link',{attrs:{"to":_vm.labelUrl,"tabindex":"-1"}},[_vm._v(_vm._s(_vm.label))])]:(_vm.label)?_c('strong',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.year)?_c('span',[_vm._v(_vm._s(_vm.year))]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }