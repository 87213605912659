<template>
    <div
        v-if="links && links.length > 0"
        class="sf-themes-card"
    >
        <div class="sf-themes-card__body">
            <h3 class="sf-themes-card__title">{{ title }}</h3>

            <ul class="sf-themes-card__list">
                <li
                    v-for="(link, index) in links"
                    :key="index"
                    class="sf-themes-card__item"
                >
                    <sf-icon icon="arrow-right" class="sf-themes-card__icon" />

                    <a
                        v-if="link.externalUrl"
                        :href="link.externalUrl"
                        target="_blank"
                        class="sf-themes-card__link"
                    >
                        {{ link.label }}
                    </a>

                    <nuxt-link
                        v-else-if="link.entry && link.entry[0]"
                        :to="$urlToPath(link.entry[0].url)"
                        class="sf-themes-card__link"
                    >
                        {{ link.label }}
                    </nuxt-link>
                </li>
            </ul>

            <div class="sf-themes-card__image">
                <sf-lazy-img
                    v-if="image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url || ''"
                    :src-tiny="image.url_tiny"
                />
            </div>
        </div>

        <div
            v-if="description"
            class="sf-themes-card__footer"
        >
            <p v-html="description" /> <!-- eslint-disable-line -->
        </div>
    </div>
</template>

<script>
import SfLazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';
import SfIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        SfIcon,
        SfLazyImg
    },
    props: {
        title: {
            type: String,
            default: null
        },
        image: {
            type: Object,
            default: null
        },
        links: {
            type: Array,
            default: null
        },
        description: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="less" src="./themes-card.less"></style>
