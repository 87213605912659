export default {
    computed: {
        scrollTop() {
            return this.$store.getters['ui/scrollPosition'];
        },
        animationPlaying() {
            return this.scrollTop < document.body.clientHeight - 1500;
        }
    },

    destroyed() {
        this.$store.commit('ui/isLayoutCollapsed', false);
    },

    watch: {
        scrollTop() {
            // If the user has reduced motion enabled, we don't want to collapse the layout on late collapse pages
            if (this.$store.getters['ui/isMotionReduced'] && this.lateCollapse) {
                return;
            }

            let collapseAt = 0;

            if (this.lateCollapse) {
                collapseAt = 300;
            }

            if (this.scrollTop > collapseAt && this.animationPlaying) {
                this.$store.commit('ui/isLayoutCollapsed', true);
            } else {
                this.$store.commit('ui/isLayoutCollapsed', false);
            }
        }
    }
};
