<template>
    <time
        v-if="date"
        class="sf-date-badge"
        :datetime="date"
    >
        <strong>{{ day }}</strong>
        <span>{{ month }}</span>
    </time>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
            default: null
        }
    },

    computed: {
        day() {
            return this.$d(
                this.$parseISO(this.date),
                'day'
            );
        },

        month() {
            return this.$d(
                this.$parseISO(this.date),
                'monthShort'
            );
        }
    }
};
</script>

<style src="./date-badge.less" lang="less"></style>
