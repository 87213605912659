<template>
    <article
        class="sf-discover-card"
        :class="{
            'sf-discover-card--has-meta': label || year,
            'sf-dicover-card--video': vimeoUrl
        }"
        :data-card-type="cardType"
        :data-card-orientation="orientation"
        :data-video-state="videoState"
    >
        <component
            :is="cardElement"
            class="sf-discover-card__clickable"
            v-bind="cardAttrs"
        >
            <div
                v-if="!vimeoUrl && !tips.length"
                class="sf-discover-card__image"
            >
                <lazy-img
                    v-if="image && image.url_sm"
                    class="lazy-img--cover"
                    :src="image.url_sm"
                    :src-tiny="image.url_tiny"
                    :srcset="`
                        ${image.url_xs} 320w,
                        ${image.url_sm} 600w,
                        ${image.url_md} 800w
                    `"
                    sizes="
                        (max-width: 380px) 320px,
                        600px
                    "
                    :width="image.width"
                    :height="image.height"
                    :alt="image.alt || ''"
                />

                <div
                    v-if="date"
                    class="sf-discover-card__date"
                >
                    <sf-date-badge :date="date" />
                </div>
            </div>

            <div class="sf-discover-card__body">
                <sf-icon
                    v-if="cardType === 'quote'"
                    icon="blockquote"
                />

                <component
                    :is="titleTag"
                    class="sf-discover-card__title"
                >
                    {{ title }}
                </component>
            </div>

            <div v-if="tips.length > 0" class="sf-discover-card__tips">
                <div
                    v-for="(tip, index) in tips"
                    :key="index"
                    class="sf-discover-card__tips__tip"
                >
                    <h3>tip {{ index + 1 }}</h3>
                    <p>{{ tip }}</p>
                </div>
            </div>

            <div
                v-if="cardType === 'video' && vimeoUrl"
                class="sf-discover-card__video"
            >
                <div
                    v-if="cardType === 'video' && vimeoUrl"
                    class="sf-discover-card__play"
                />

                <sf-vimeo-embed
                    :title="title"
                    :url="vimeoUrl"
                    :accessible-url="accessibleVimeoUrl"
                    class="vimeo-embed--cover"
                    @play="videoState = 'playing'"
                    @pause="videoState = 'paused'"
                    @ended="videoState = 'ended'"
                />
            </div>
        </component>

        <div class="sf-discover-card__meta">
            <template v-if="label && labelUrl">
                <a
                    v-if="isExternalLabelUrl"
                    :href="labelUrl"
                    tabindex="-1"
                >
                    {{ label }}
                </a>
                <nuxt-link v-else :to="labelUrl" tabindex="-1">{{ label }}</nuxt-link>
            </template>

            <strong v-else-if="label">{{ label }}</strong>

            <span v-if="year">{{ year }}</span>
        </div>
    </article>
</template>

<script>
import { isExternalURL } from '~/helpers/isExternalUrl';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img';
import SfIcon from '~/patterns/atoms/icon/icon.vue';
import SfDateBadge from '~/patterns/atoms/date-badge/date-badge.vue';
import SfVimeoEmbed from '~/patterns/molecules/vimeo-embed/vimeo-embed';

export default {
    components: {
        LazyImg,
        SfIcon,
        SfDateBadge,
        SfVimeoEmbed
    },

    props: {
        cardType: {
            type: String,
            default: 'entry',
            validate(value) {
                return ['entry', 'video', 'quote', 'tips'].includes(value);
            }
        },

        orientation: {
            type: String,
            default: 'landscape',
            validate(value) {
                return ['landscape', 'portrait'].includes(value);
            }
        },

        element: {
            type: String,
            default: null
        },

        title: {
            type: String,
            default: null
        },

        titleTag: {
            type: String,
            default: 'h2'
        },

        image: {
            type: Object,
            default: null
        },

        vimeoUrl: {
            type: String,
            default: null
        },

        accessibleVimeoUrl: {
            type: String,
            default: null
        },

        tips: {
            type: Array,
            default: () => []
        },

        label: {
            type: String,
            default: null
        },

        labelUrl: {
            type: [String, Object],
            default: null
        },

        year: {
            type: [String, Number],
            default: null
        },

        date: {
            type: String,
            default: null
        },

        to: {
            type: [Object, String, Boolean],
            default: false
        }
    },

    data() {
        return {
            videoState: null
        };
    },

    computed: {
        isExternalLabelUrl() {
            return isExternalURL(this.labelUrl);
        },

        cardElement() {
            if (!this.element && this.to) {
                return 'nuxt-link';
            }

            if (!this.element) {
                return 'div';
            }

            return this.element;
        },

        cardAttrs() {
            const cardAttrs = {};

            if (this.cardElement === 'nuxt-link' && this.to) {
                cardAttrs.to = this.to;
            }

            if (this.cardElement === 'a' && this.to) {
                cardAttrs.href = this.to;
            }

            if (this.cardElement === 'button') {
                cardAttrs.type = 'button';
            }

            return cardAttrs;
        }
    }
};
</script>

<style lang="less" src="./discover-card.less"></style>
